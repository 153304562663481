@keyframes selected_object_animation {
  from {
    stroke-width: 1px;
    stroke: #ccc;
  }

  50% {
    stroke-width: 5px;
    stroke: #000;
  }

  to {
    stroke-width: 1px;
    stroke: #ccc;
  }
}

/*
path.selected_object,
circle.selected_object,
g.selected_object {
  animation-duration: 2s;
  animation-name: selected_object_animation;
  animation-iteration-count: infinite;
}
*/

circle.node,
rect.node,
path.node,
g.node {
  cursor: pointer;
  stroke: #000;
  fill: #ccc;
  stroke-width: 0.5px;
}

text.node-label {
  cursor: pointer;
  stroke: none;
  fill: #666;
}

circle.scatter {
  fill: gray;
}

circle.legend,
path.legend {
  stroke: #000;
  stroke-width: 0.5px;
}

.hiv-trace-problematic {
  stroke: #f00 !important;
  stroke-width: 2px !important;
}

.hiv-trace-selected {
  stroke-width: 0px !important;
}

.hiv-trace-muted {
  opacity: 0.25;
}

g.node-group {
  cursor: pointer;
  stroke: #000;
}

.hiv-trace-legend {
  font-family: sans-serif;
  font-size: 11pt;
}

path.cluster,
circle.cluster {
  cursor: pointer;
  fill: #ccc;
  stroke: #000;
  stroke-width: 2.5px;
}

.histogram {
  fill: #ccc;
  stroke: #000;
  stroke-width: 1px;
}

.bar text {
  fill: #fff;
}

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.link {
  stroke: #aaaaaa;
  stroke-width: 1.5px;
  fill: none;
}

.mspp-link {
  stroke: #666666;
  stroke-width: 2px;
  fill: none;
}

.core-link {
  stroke: #d9534f !important;
  stroke-width: 3px !important;
  fill: none;
}

.unsupported,
.line {
  fill: none;
  stroke: red;
  stroke-width: 1.5px;
  stroke-dasharray: 2, 2;
}

.chord path {
  fill-opacity: 0.67;
  stroke: #000;
  stroke-width: 0.5px;
}

#file-progress {
  display: none;
}

.removed {
  stroke: #cc3333;
  stroke-opacity: 0.3;
}

#network_tag {
  margin-top: 15px;
}

.nav-trace {
  margin-top: 5px;
}

#network_ui_bar > .input-group-btn {
  padding-bottom: 5px;
  height: 36px !important;
}

#network_ui_bar > .input-group-btn > .btn {
  height: 36px !important;
}

#hivtrace-export-image {
  height: 36px;
}

input.form-control {
  height: 36px !important;
}

.hivtrace-download-button {
  margin-left: 5px;
  margin-bottom: 5px;
}

#network_ui_bar_filter {
  z-index: 0;
}

/* Internet Explorer specific definitions */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ specific styles go here */
  path.selected_object,
  circle.selected_object {
    stroke-width: 5px;
    stroke: #000;
  }
}

g.selected_object,
path.selected_object,
circle.selected_object {
  stroke-width: 5px;
  stroke: #000;
}

g.multi_sequence,
path.multi_sequence,
circle.multi_sequence {
  stroke-width: 3px;
  stroke: #350e6b;
  /*stroke-dasharray: 10 1;*/
}

path.injected_object,
circle.injected_object {
  stroke-width: 2px !important;
  stroke: #d9534f !important;
  /*stroke-dasharray: 10 1;*/
}

.area {
  stroke: #444;
  stroke-width: 0.5px;
  fill-opacity: 0.75;
}

.trend {
  stroke-width: 1px;
  stroke-opacity: 0.75;
  fill-opacity: 0.1;
}

.tracer {
  fill: none;
  stroke-width: 0.5px;
}

.annotation-line {
  fill: none;
  stroke: #999;
  stroke-width: 2px;
}

.annotation-text {
  font: 18px sans-serif;
  text-anchor: left;
  fill: #000;
}

.cloned-cluster-tab .show-small-clusters-button {
  display: none;
}

#hiv-trace-dynamics-svg {
  cursor: move;
}

.hiv-trace-legend {
  cursor: all-scroll;
}

.algolia-autocomplete {
  width: 100%;
}

.algolia-autocomplete .aa-input,
.algolia-autocomplete .aa-hint {
  width: 100%;
}

.algolia-autocomplete .aa-hint {
  color: #999;
}

.algolia-autocomplete .aa-dropdown-menu {
  width: 100%;
  background-color: #fff;
  border: 1px solid #999;
  border-top: none;
}

.algolia-autocomplete .aa-dropdown-menu .aa-suggestion {
  cursor: pointer;
  padding: 5px 4px;
}

.algolia-autocomplete .aa-dropdown-menu .aa-suggestion.aa-cursor {
  background-color: #b2d7ff;
}

.algolia-autocomplete .aa-dropdown-menu .aa-suggestion em {
  font-weight: bold;
  font-style: normal;
}

.table-fixed tbody {
  height: 80vh;
  overflow: auto;
  display: block;
}

.table-fixed thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-fixed tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-smaller td {
  font-size: max(0.75em, 10pt);
}

.table-smaller th {
  font-size: max(0.75em, 10pt);
}

.list-smaller li {
  font-size: max(0.75em, 10pt);
}

#colorPickerRow {
  background-color: beige;
  padding: 10px;
}

#colorPickerOption {
  display: none;
  padding-top: 10px;
}

.color-picker-toggle {
  color: green;
}

.main-content-panel {
  padding-top: 10px;
}

.fa-caret-down {
  padding-left: 3px;
}

.dropdown button {
  padding: 5px 10px 5px 10px;
}

.time_axis path,
.time_axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.time_axis text {
  fill: black;
  stroke: none;
  font-family: sans-serif;
  font-size: 10px;
}
